var angularModule = angular.module('t2g.booking.service.api', []);

module.exports = angularModule;

angularModule.factory('OfficeResource', function ($http,
                                           apiBase) {
  return {
    get: function (slug) {
      return $http.get(apiBase + '/office/' + slug + '/bookingProfile');
    }
  };
});

angularModule.factory('PaymentResource', function ($http,
                                            apiBase) {
  return {
    getStripeSecret: function (data) {
      return $http.post(apiBase + '/payment/stripe/secret', data);
    }
  };
});


angularModule.factory('OfficeStorage', function ($q,
                                          $location,
                                          $rootScope,
                                          $timeout,
                                          gettextCatalog,
                                          OfficeResource,
                                          SessionService) {
  return {
    get: function (doRefresh, slug) {
      var deferred = $q.defer();
      var office = SessionService.getOffice();

      if (!office || office && doRefresh) {
        OfficeResource.get(slug).then(
          function (response) {
            var channel = $location.search().channel;
            var lang = $location.search().lang || 'de';
            var office = response.data.office;
            var appointmentColors = _.get(office, 'settings.widget.appointment');
            var customTexts = _.get(office, 'settings.widget.appointment.text', {});
            var translationOverwrite = {};
            var translationMap = {
              'select_services': 'Select your services',
              'services': 'services',
              'appointment': 'appointment',
              'contact': 'contact',
              'forward': 'forward',
              'back': 'back',
              'unspecific_employee': 'unspecific employee',
              'book_appointment': 'book appointment',
              'congratulations': 'Congratulations! You successfully booked an appointment. Please await the appointment confirmation from the service provider.',
              'check_spam': 'Please also check your spam folder emails!',
              'book_another_appointment': 'Book another appointment at {{SuccessCtrl.office.name}}',
              'no_appointments_free': 'No free appointments in next 6 weeks'
            };

            _.forEach(customTexts, function (customText, key) {
              if (customText && customText.length > 0) {
                translationOverwrite[translationMap[key]] = customText;
              }
            });

            $timeout(function () {
              gettextCatalog.setStrings(lang, translationOverwrite);

              if (appointmentColors && channel !== 'termin2go') {
                document.documentElement.style.setProperty('--t2g-bg', appointmentColors.bg);
                document.documentElement.style.setProperty('--t2g-primary', appointmentColors.primary);
                document.documentElement.style.setProperty('--t2g-primary-contrast', appointmentColors.primaryContrast);
                document.documentElement.style.setProperty('--t2g-service-group-header-bg', appointmentColors.serviceGroupHeaderBg);
                document.documentElement.style.setProperty('--t2g-text-color', appointmentColors.textColor);
                document.documentElement.style.setProperty('--t2g-text-secondary-color', appointmentColors.secondaryTextColor);
                document.documentElement.style.setProperty('--t2g-bottom-bar-bg', appointmentColors.bottomBarBg);
                document.documentElement.style.setProperty('--t2g-bottom-bar-text-color', appointmentColors.bottomBarTextColor);
              }

              SessionService.setOffice(office);
              $rootScope.$emit('office:loaded', office);
              deferred.resolve(office);
            });
          },
          function (error) {
            deferred.reject(error);

          }
        );
      } else {
        $timeout(function () {
          var appointmentColors = _.get(office, 'settings.widget.appointment');
          var channel = $location.search().channel;

          if (appointmentColors && channel !== 'termin2go') {
            document.documentElement.style.setProperty('--t2g-bg', appointmentColors.bg);
            document.documentElement.style.setProperty('--t2g-primary', appointmentColors.primary);
            document.documentElement.style.setProperty('--t2g-primary-contrast', appointmentColors.primaryContrast);
            document.documentElement.style.setProperty('--t2g-service-group-header-bg', appointmentColors.serviceGroupHeaderBg);
            document.documentElement.style.setProperty('--t2g-text-color', appointmentColors.textColor);
            document.documentElement.style.setProperty('--t2g-text-secondary-color', appointmentColors.secondaryTextColor);
            document.documentElement.style.setProperty('--t2g-bottom-bar-bg', appointmentColors.bottomBarBg);
            document.documentElement.style.setProperty('--t2g-bottom-bar-text-color', appointmentColors.bottomBarTextColor);
          }
        });

        $rootScope.$emit('office:loaded', office);

        deferred.resolve(office);
      }

      return deferred.promise;
    }
  }
});

angularModule.factory('BookingResource', function ($http,
                                            apiBase) {

  return {
    create: function (data) {
      return $http.post(apiBase + '/booking', data);
    },
    createWithCheckout: function (data) {
      return $http.post(apiBase + '/bookingWithCheckout', data);
    },
    createWalkIn: function (data) {
      return $http.post(apiBase + '/booking/walk-in', data);
    },
    createWalkInWithCheckout: function (data) {
      return $http.post(apiBase + '/booking/walk-in-with-checkout', data);
    },
  };
});

angularModule.factory('TimeSlotsResource', function ($http,
                                              apiBase) {

  var resourceUrl = apiBase + '/timeSlots';

  return {
    get: function (data) {
      return $http.get(resourceUrl, {
        params: data
      });
    }
  };
});


angularModule.factory('TimeSlotStorage', function ($q,
                                            MediaQueryService,
                                            SessionService,
                                            TimeSlotsResource) {
  return {
    get: function (startDate, serviceQueryParam, slug) {
      var deferred = $q.defer();
      var services = SessionService.getTemp().services;
      var start = startDate || moment();
      var isMobile = MediaQueryService.isMobile();
      var range = isMobile ? 4 : 6;
      var end = moment(start).add(range, 'days');
      var options = {
        slug: slug,
        services: '',
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD')
      };

      if (!serviceQueryParam) {
        for (var i = 0, serviceLen = services.length; i < serviceLen; i++) {
          var service = services[i];

          if (i > 0) {
            options.services += ',';
          }

          options.services += service._id;
        }
      } else {
        options.services = serviceQueryParam;
      }

      TimeSlotsResource.get(options).then(
        function (response) {
          deferred.resolve(response.data);
        },
        function (error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }
  }
});
