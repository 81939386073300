var angularModule = angular.module('t2g.booking.directive.streetCheck', []);
module.exports = angularModule;

angularModule.directive('streetCheck', [function() {
  return {
    require: 'ngModel',
    link: function(scope, elem, attrs, ctrl) {
      if (!ctrl) return;
      ctrl.$validators.streetCheck = function(value) {
        return value.match(/[a-zA-Z]+/i) && value.match(/\d+/i);
      };
    }
  };
}]);
