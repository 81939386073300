var PaymentCtrl = function ($rootScope,
                            $state,
                            $stateParams,
                            SessionService,
                            officeResolve) {
  this.rootScope = $rootScope;
  this._state = $state;
  this._stateParams = $stateParams;
  this._booking = SessionService.getBooking();
  this._payment = SessionService.getPayment();
  this._stripe = Stripe('pk_test_51IIGznIiCXF3p4V6VQOHt0KTZF2cCQuUJDaFy25PqskFa7TegqlZafovWxMKqHhz68HphdczuJD3sDJHv2LkeiJN00UDqmqysO');
  var appointmentColors = _.get(officeResolve, 'settings.widget.appointment');
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: appointmentColors.primary,
      colorBackground: appointmentColors.bg,
      colorText: appointmentColors.textColor,
      colorDanger: '#f44336',
      fontFamily: '\'Roboto\', sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px',
    },
  };

  const options = {
    clientSecret: this._payment.stripeSecret,
    appearance,
  };

  // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
  const elements = this._stripe.elements(options);

  // Create and mount the Payment Element
  const paymentElement = elements.create('payment');
  paymentElement.mount('#payment-element');

  $rootScope.$on('onPayBtnClickWalkIn', async (data) => {
    const { error } = await this._stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this._state.href('booking.walkInSuccess', {}, {absolute: true}),
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      console.error(error.message);
      showMessage(error.message);
    } else {
      console.error(error);
      console.error('An unexpected error occured.');
    }
  });

  $rootScope.$on('onPayBtnClick', async (data) => {
    const { error } = await this._stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this._state.href('booking.success', {}, {absolute: true}),
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      console.error(error.message);
      showMessage(error.message);
    } else {
      console.error(error);
      console.error('An unexpected error occured.');
    }
  });
};

module.exports = PaymentCtrl;
