var AppointmentCtrl = function ($scope,
                                $state,
                                $stateParams,
                                gettextCatalog,
                                data,
                                SessionService) {

  var disableEmployeeChooser = false;
  var self = this;
  var combinations = data.appointmentResolve.combinations;
  var days = data.appointmentResolve.days;
  this.scope_ = $scope;
  this.office_ = data.officeResolve;
  this.state_ = $state;
  this._stateParams = $stateParams;
  this.range_ = days.length;
  this.selectedDay = null;
  this.scope_.booking = SessionService.getBooking();
  this.scope_.temp = SessionService.getTemp();
  this.template = _.get(this.office_, 'settings.widget.appointment.calendarStyle', 'week');

  $scope.$emit('contact-form-send', false);

  if (this.scope_.booking && this.scope_.booking.slot && this.scope_.booking.slot.time) {
    this.selectedDay = moment(this.scope_.booking.slot.time).format('YYYY-MM-DD');
  } else {
    this.selectedDay = days[0].date.format('YYYY-MM-DD');
  }

  if ($stateParams.channel) {
    this.scope_.booking.channel = $stateParams.channel;
  }

  if (combinations) {
    if (!this.office_.settings.disableEmployeeChooser) {
      disableEmployeeChooser = combinations.length <= 2;
    } else {
      disableEmployeeChooser = this.office_.settings.disableEmployeeChooser;
    }

    // translate unspecific employee from API
    combinations[0].employees[0].name = gettextCatalog.getString('unspecific employee');

    $scope.model = {
      disableEmployeeChooser: disableEmployeeChooser,
      start: days[0].date,
      end: days[this.range_ - 1].date,
      days: days,
      combinations: combinations,
      selectedSlot: null,
      showPrev: true,
      employees: [],
      open: false
    };

    // first time, set unspecific employee combination
    if (!this.scope_.temp.combination) {
      this.scope_.temp.combination = combinations[0];
    } else {
      // get combination and set correct one
      angular.forEach(combinations, function (combination) {
        if (self.isPicked(combination)) {
          self.scope_.temp.combination = combination;
        }
      });
    }

    if (this.template === 'day') {
      self.groupDaySlots();
    }
  } else {
    $scope.model = {
      disableEmployeeChooser: true,
      start: days[0].date,
      end: days[this.range_ - 1].date,
      days: days,
      nextAvailableSlot: data.appointmentResolve.nextAvailableSlot,
      nextAvailableSlotFormatted: moment(data.appointmentResolve.nextAvailableSlot).format('YYYY-MM-DD'),
      combinations: null,
      selectedSlot: null,
      showPrev: true,
      employees: [],
      open: false
    };
  }
};

AppointmentCtrl.prototype.groupDaySlots = function () {
  var self = this;
  var slotsOfDay = this.scope_.temp.combination[this.selectedDay]
  this.scope_.model.daySlots = {
    morning: [],
    lunch: [],
    afternoon: [],
    evening: []
  };

  _.forEach(slotsOfDay, function (slot) {
    var slotHours = moment(slot.time).hours();

    if (slotHours < 11) {
      self.scope_.model.daySlots.morning.push(slot);
    } else if (slotHours >= 11 && slotHours < 14) {
      self.scope_.model.daySlots.lunch.push(slot);
    } else if (slotHours >= 14 && slotHours < 17) {
      self.scope_.model.daySlots.afternoon.push(slot);
    } else {
      self.scope_.model.daySlots.evening.push(slot);
    }
  });
}


/**
 *
 */
AppointmentCtrl.prototype.next = function () {
  this.load(this.range_);
};


/**
 *
 */
AppointmentCtrl.prototype.prev = function () {
  this.load(-this.range_);
};

/**
 *
 * @param slot
 */
AppointmentCtrl.prototype.select = function (slot) {
  if (angular.equals(this.scope_.booking.slot, slot)) {
    this.scope_.booking.slot = null;
  } else {
    this.scope_.booking.slot = slot;
    this.scope_.$emit('slot-selected', slot);
  }
};

AppointmentCtrl.prototype.selectDay = function (day) {
  this.selectedDay = day.dateKey;
  this.groupDaySlots();
};

AppointmentCtrl.prototype.isSelectedDay = function (day) {
  return this.selectedDay === day.dateKey;
};

/**
 *
 * @param slot
 * @returns {*}
 */
AppointmentCtrl.prototype.isSelected = function (slot) {
  return angular.equals(this.scope_.booking.slot, slot);
};


/**
 *
 * @param diff
 */
AppointmentCtrl.prototype.load = function (diff) {
  var start = moment(this.scope_.model.start).add(diff, 'days').format('YYYY-MM-DD');
  this.state_.transitionTo('booking.appointments', {start: start, officeSlug: this._stateParams.officeSlug});
};


/**
 *
 */
AppointmentCtrl.prototype.goToNextFree = function () {
  this.state_.transitionTo('booking.appointments', {
    start: moment($scope.model.nextAvailableSlot).format('YYYY-MM-DD'),
    officeSlug: this._stateParams.officeSlug
  });
};


/**
 *
 * @param combination
 * @returns {boolean}
 */
AppointmentCtrl.prototype.isPicked = function (combination) {
  return angular.equals(combination.employees, this.scope_.temp.combination.employees);
};


/**
 *
 * @param combination
 */
AppointmentCtrl.prototype.selectCombination = function (combination) {
  this.scope_.temp.combination = combination;
  this.scope_.opened = false;
  if (this.template === 'day') {
    this.groupDaySlots();
  }
};

AppointmentCtrl.prototype.getSlotsOfDay = function () {
  return this.selectedDay ? this.scope_.temp.combination[this.selectedDay] : [];
}

AppointmentCtrl.prototype.hasAppointments = function (dateKey) {
  return dateKey && this.scope_.temp.combination && this.scope_.temp.combination[dateKey] ? !!this.scope_.temp.combination[dateKey].length : false;
}

module.exports = AppointmentCtrl;
